import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      creditAllowanceTotal: {
        allowance: 0,
        credit: 0,
      },
      appliedCreditAllowances: {
        allowance: false,
        credit: true,
      },
      updated: 1,
    };
  },
  computed: {
    ...mapGetters({
      loggedInCustomer: 'auth/loggedInUser',
      storeInformation: 'storeInformation',
      creditsAllowances: 'creditsAllowances/creditsAllowances',
    }),
    orderTotalAndCreditAllowance() {
      return (
        parseFloat(this.orderTotal.roundCeil()) - parseFloat(this.creditAllowanceSummary.roundCeil())
      ).roundCeil();
    },
    remainingBalance() {
      return this.orderTotalAndCreditAllowance;
    },
    creditAllowancesForUse() {
      return ['Allowance', 'Credit']
        .map((i) => ({
          type: i.toLowerCase(),
          amount:
            this.creditsAllowances[i.toLowerCase()] &&
            this.creditsAllowances[i.toLowerCase()][0] &&
            this.creditsAllowances[i.toLowerCase()][0].sum,
          applied: null,
        }))
        .filter((i) => i.amount > 0);
    },
    creditAllowanceSummary() {
      return Object.values(this.creditAllowanceTotal).reduce((a, b) => a + b, 0);
    },
    allCreditAllowancesApplied() {
      return this.appliedCreditAllowances.credit && this.appliedCreditAllowances.allowance;
    },
  },
  methods: {
    applyCreditAllowance(index, value) {
      const data = this.creditAllowancesForUse[index];
      if (value) {
        const parsedValue = parseFloat(value);
        this.creditAllowanceTotal[data.type] += parsedValue;
        this.appliedCreditAllowances[data.type] = true;
        data.applied = parsedValue;
      } else {
        this.creditAllowanceTotal[data.type] = 0;
        this.appliedCreditAllowances[data.type] = false;
        data.applied = null;
      }
      this.error = null;
    },
    inputCreditAllowance(index, value) {
      const data = this.creditAllowancesForUse[index];
      this.appliedCreditAllowances[data.type] = false;
      this.error = null;
    },
    resetCreditAllowance() {
      const emptyObject = {
        allowance: 0,
        credit: 0,
      };
      Object.assign(this.creditAllowanceTotal, emptyObject);
      this.updated = Date.now();
    },
  },
};
