
import { mapActions, mapGetters } from 'vuex';
import { phoneToString } from '@mtntop/utils/src/components/PhoneField/utils/filters';
import creditAllowanceMixin from '@/components/checkout/credit.allowence.mixin';
import UserAvatar from '@/components/UserAvatar';

export default {
  filters: {
    phone: phoneToString,
  },
  components: {
    UserAvatar,
  },
  data() {
    return {
      customerManager: null,
      loaded: false,
    };
  },
  mixins: [creditAllowanceMixin],
  props: {
    infoBar: Object,
    accountManager: Object,
    customerName: Boolean,
    customerCredit: Boolean,
    customerAllowance: Boolean,
    customerRole: Boolean,
    customerLogo: Boolean,
  },
  async mounted() {
    this.customerManager = Object.assign({}, this.customerAccountManager);
    if (!this.customerAccountManager && this.customer && this.customer.userId) {
      this.customerManager = await this.getAccountManager(this.customer.userId);
    }
  },
  computed: {
    ...mapGetters({
      customer: 'auth/loggedInUser',
      customerAccountManager: 'auth/customerAccountManager',
      storeCurrencySign: 'storeCurrencySign',
    }),
    creditAllowancesForDisplay() {
      let types = [];
      if (this.customerCredit) {
        types.push('credit');
      }
      if (this.customerAllowance) {
        types.push('allowance');
      }
      return this.creditAllowancesForUse.filter((i) => types.includes(i.type));
    },
    accountManagerPhone() {
      const phoneNumber = phoneToString(this.customerManager.phone);
      const regexp = new RegExp('\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]?\\d{2,})+', 'g');
      return (phoneNumber.match(regexp) || [])[0];
    },
    phoneInternationalLink() {
      return (this.accountManagerPhone || '').replace(/\s+|-/g, '');
    },
    companyData() {
      return {};
    },
  },
  methods: {
    ...mapActions({
      getAccountManager: 'auth/get_account_manager',
    }),
  },
};
